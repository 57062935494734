<template>
  <header
    class="header"
    :class="{ base: !props.withBackButton, 'full-width': fullWidth }"
  >
    <button v-if="props.withBackButton" class="button" @click="emit('back')">
      <StIcon name="chevron-left" :size="20" />
    </button>
    <span v-if="title" class="title">{{ title }}</span>
    <slot v-else />
    <button class="button close" @click="close">
      <StIcon name="cross-large" :size="20" />
    </button>
  </header>
</template>

<script setup lang="ts">
const props = defineProps<{
  withBackButton?: boolean
  title?: string
  fullWidth?: boolean
  disableRouterBack?: boolean
}>()

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'close'): void
}>()

const router = useRouter()
function close() {
  emit('close')

  if (!props.disableRouterBack) {
    router.replace({ query: {} })
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: var(--spacing-050);

  &.base {
    padding-left: var(--spacing-200);
  }

  &.full-width {
    padding-left: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;

  &.close {
    margin-left: auto;
  }
}

.title {
  font: var(--mobile-title-2-semibold);
}
</style>
